/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useAuthContext } from '../../../../../context/auth.context'
import { useDashboardContext } from '../../../../../context/dashboard.context'
import { dashboardConst } from '../../../../../utils/action.constant'
import {
	useLoadAssetChannels,
	useLoadSIPPayoutWidgets,
	useLoadDynamicPayoutAssetReporteesSA,
	useLoadDynamicPayoutAssetManagerSA,
	useLoadSIPWidgetsAccountDetails,
	useLoadSIPWidgetsSARoleList,
	useLoadSIPPayoutWidgetsMonthWise,
	useLoadSIPWidgetsAccountcodesList,
	LoadSIPWidgetsAccountcodesMonthWiseData,
} from '../../../../../hooks/dashboard.hooks'
import {
	Box,
	Divider,
	Flex,
	IconButton,
	Skeleton,
	SkeletonText,
	Stack,
	Text,
	VStack,
	Tooltip,
} from '@chakra-ui/react'
import DrillDownTable, {
	TH,
	TR,
	TD,
} from '../../../../../components/dashboard-table'
import Scrollbar from 'react-custom-scrollbars-2'
import ReactExport from 'react-data-export'
import { BiDownload } from 'react-icons/bi'
import { isRoleAccount, isRoleSelf } from '../../../../../config/roles.config'
import { calculateStartEndDate } from '../../../../../utils/dashboard.utils'
import { sectionDetailedTrack } from '../../../../repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from '../../../../repository/repo.utils'
import { useMutation } from 'react-query'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

function WidgetSIPDynamicViewSA() {
	const {
		state: { queryFilters, sipReporteeList, activeFilterOption },
		dispatch: dashboardDispatch,
	} = useDashboardContext()
	const {
		state: { authData },
	} = useAuthContext()
	const [download, setDownload] = useState(false)
	const {
		mutate: mutateLoadReportees,
		data: reporteeList,
		isLoading: isReporteesLoading,
		isSuccess: isReporteesSuccess,
	} = useLoadDynamicPayoutAssetReporteesSA()

	const {
		mutate: mutateLoadChannel,
		data: channelList,
		isLoading: isChannelLoading,
	} = useLoadAssetChannels()

	const {
		mutate: mutateLoadManagerDetailsData,
		data: managerDetails,
		isLoading: isManagerLoading,
		isSuccess: isManagerSuccess,
	} = useLoadDynamicPayoutAssetManagerSA()

	const {
		mutate: mutateLoadSIPWidget,
		data: sipWidgetList,
		isLoading: isReporteesSipKpiLoading,
		isSuccess: isReporteesSipKpiSuccess,
	} = useLoadSIPPayoutWidgetsMonthWise()

	const {
		mutate: mutateLoadManagerTargets,
		data: sipWidgetManagerTargetList,
		isLoading: isManagerSipKpiLoading,
		isSuccess: isManagerSipKpiSuccess,
	} = useLoadSIPPayoutWidgets()

	const {
		mutate: mutateLoadAccountDetails,
		data: sipWidgetAccountDetails,
		isLoading: isAccountDetailsLoading,
		isSuccess: isAccountDetailsSuccess,
	} = useLoadSIPWidgetsAccountDetails()

	const { mutate: mutateRoleList, data: roleList } =
		useLoadSIPWidgetsSARoleList()

	const { mutate: mutateLoadAccountList, data: sipWidgetAccountList } =
		useLoadSIPWidgetsAccountcodesList()

	const [isSelf, setIsSelf] = useState(true)
	const [isAccountManager, setIsAccountManager] = useState(false)
	const [widgetAccounts, setWidgetAccounts] = useState([])
	const [getAccountAsset_id, setAccountAssetId] = useState(null)
	const [scrollAtBottom, setScrollAtBottom] = useState(false)
	const [hasMoreData, setHasMoreData] = useState(true)
	const isMobileView = localStorage.getItem('IS_MOBILE_VIEW')
	const [page_value, setPageValue] = useState(0)
	const limit_value = 200
	const [accountHeaders, setAccountHeaders] = useState([])
	const [isRoleAccess, setIsRoleAccess] = useState(false)
	const [accountMonthwiseData, setAccountMonthwiseData] = useState([])
	const [showAccountTable, setShowAccountTable] = useState(false)

	useEffect(() => {
		return () => {
			dashboardDispatch({
				type: dashboardConst.REMOVE_SIP_RESOURCE,
			})
		}
	}, [])

	useEffect(() => {
		getRoleList()
	}, [])

	useEffect(() => {
		let reportee = {}
		// if (queryFilters?.selectedCreator?.label === 'All') {
		//   clearReporteeToStack()
		// }
		// if (reportee?.asset_id) {
		//   addReportee()
		//   addReporteeToStack(reportee, true)
		// }
		clearReporteeToStack()
	}, [queryFilters.selectedCreator])

	const addReportee = data => {
		dashboardDispatch({
			type: dashboardConst.ADD_REPORTEES,
			payload: data,
		})
	}
	const clearReporteeToStack = () => {
		dashboardDispatch({
			type: dashboardConst.CLEAR_REPORTEE_STACK,
		})
	}

	useEffect(() => {
		checkUserRole()
	}, [
		queryFilters.selectedCreator,
		queryFilters.selectedPeriod,
		queryFilters.selectedPeriodType,
		queryFilters.selectedSipSegment,
		queryFilters.selectedYear,
	])

	const getRoleList = () => {
		mutateRoleList({
			asset_id: authData.asset_id,
			segment: '358',
		})
	}

	const checkUserRole = (manager_id = false) => {
		const { asset_type_id, asset_id, operating_asset_first_name } = authData

		if (isRoleAccount.includes(manager_id ? manager_id : asset_type_id)) {
			setIsAccountManager(true)
		} else {
			if (isRoleSelf.includes(asset_type_id)) {
				setIsSelf(false)
			}
		}
		let manager = {}
		if (
			!!queryFilters &&
			queryFilters.hasOwnProperty('selectedCreator') &&
			queryFilters.selectedCreator?.value !== 0
		) {
			manager = {
				operating_asset_first_name:
					queryFilters.selectedCreator?.operating_asset_first_name,
				asset_id: queryFilters.selectedCreator?.asset_id,
				label: queryFilters.selectedCreator?.operating_asset_first_name,
				asset_type_id: queryFilters.selectedCreator?.asset_type_id,
			}
		} else {
			// queryFilters.defaultCreator = {label: authData.operating_asset_first_name, asset_id : authData.asset_id}
			manager = {
				operating_asset_first_name,
				asset_id,
				asset_type_id,
			}
		}
		// if (isAccountManager) {
		//   loadAccountForAsset(manager)
		// } else {

		loadResourceForAsset(manager)

		// loadSIPWidgetsAccountDetails(manager.asset_id)
		// }
	}

	const loadManagerTargets = manager => {
		const {
			selectedPeriodType,
			selectedPeriod,
			selectedSipSegment,
			selectedYear,
		} = queryFilters

		mutateLoadManagerTargets({
			creator: manager,
			segment: 358,
			timeline: selectedPeriodType,
			period: selectedPeriod,
			year: selectedYear,
			flag: 11,
			reportees_asset_id_list: JSON.stringify([manager.asset_id]),
			is_sip_dynamic_payout: 0,
		})
	}

	const loadAccountForAsset = manager => {
		const {
			selectedPeriodType,
			selectedPeriod,
			selectedSipSegment,
			selectedYear,
		} = queryFilters
		let { startDate, endDate } = calculateStartEndDate({
			selectedPeriod,
			selectedPeriodType,
			selectedYear,
		})
		mutateLoadChannel(
			{
				segment: selectedSipSegment,
				manager,
				startDate,
				endDate,
			},
			{
				onSuccess: data => {
					if (data.length) {
						loadSIPWidgetForAsset(manager)
					}
				},
			}
		)
	}

	const loadResourceForAsset = (asset, isManager = false) => {
		const reportee = asset
		console.log('selectedReportee', reportee)
		let isRoleExist = !!roleList
			? Object.values(roleList).includes(reportee.asset_type_id)
			: false
		console.log('isRoleExist', isRoleExist)
		setIsRoleAccess(isRoleExist)
		const {
			selectedSipSegment,
			selectedPeriodType,
			selectedPeriod,
			selectedYear,
		} = queryFilters
		let { startDate, endDate, startYear } = calculateStartEndDate({
			selectedPeriod,
			selectedPeriodType,
			selectedYear,
		})
		console.log('payload', {
			flag: isRoleExist ? 10 : 11,
			creator: reportee,
			timeline_id: !!selectedPeriodType ? selectedPeriodType.value : 1,
			year: !!selectedYear ? selectedYear.value.replaceAll(' ', '') : '',
			periodTypeId: !!selectedPeriod ? selectedPeriod.value : 1,
			segment: '358',
			startDate,
			endDate,
		})
		mutateLoadReportees(
			{
				flag: isRoleExist ? 10 : 11,
				creator: reportee,
				timeline_id: !!selectedPeriodType ? selectedPeriodType.value : 1,
				year: !!selectedYear ? selectedYear.value.replaceAll(' ', '') : '',
				periodTypeId: !!selectedPeriod ? selectedPeriod.value : 1,
				segment: '358',
				startDate,
				endDate,
			},
			{
				onSuccess: data => {
					if (reportee.asset_id !== authData.asset_id)
						addReporteeToStack(reportee, isManager)
					if (data.length) {
						loadSIPWidgetForAsset(
							data.filter(x => !!x.asset_id).map(v => v.asset_id)
						)
					}
				},
			}
		)
		setAccountMonthwiseData([])
		setWidgetAccounts([])
		setShowAccountTable(false)
		loadSelfData(reportee)
	}

	const loadSelfData = manager => {
		const {
			selectedPeriodType,
			selectedPeriod,
			selectedSipSegment,
			selectedYear,
		} = queryFilters
		mutateLoadManagerDetailsData(
			{
				manager_id: manager.asset_id,
				timeline_id: !!selectedPeriodType ? selectedPeriodType.value : 1,
				year: !!selectedYear ? selectedYear.value.replaceAll(' ', '') : '',
				period: !!selectedPeriod ? selectedPeriod.value : 1,
				segment: '358',
			},
			{
				onSuccess: data => {
					if (
						!!data &&
						data.filter(item => item.asset_flag_frontline === 1).length
					) {
						setShowAccountTable(true)
						setWidgetAccounts([])
						setAccountMonthwiseData([])
						setPageValue(0)
						setAccountAssetId(data[0].asset_id)
						setScrollAtBottom(false)
						loadSIPWidgetsAccountDetails(manager.asset_id, true)
					}
				},
			}
		)

		mutateLoadManagerTargets({
			creator: manager,
			segment: 358,
			timeline: selectedPeriodType,
			period: selectedPeriod,
			year: selectedYear,
			flag: 11,
			reportees_asset_id_list: JSON.stringify([manager.asset_id]),
			is_sip_dynamic_payout: 0,
		})
	}

	const loadSIPWidgetForAsset = (reporteelist, isAccount = false) => {
		const {
			selectedPeriodType,
			selectedPeriod,
			selectedSipSegment,
			selectedYear,
		} = queryFilters
		let { startDate, endDate, startYear } = calculateStartEndDate({
			selectedPeriod,
			selectedPeriodType,
			selectedYear,
		})

		mutateLoadSIPWidget({
			creator: reporteelist,
			segment: 358,
			timeline: selectedPeriodType,
			period: selectedPeriod,
			year: selectedYear,
			isManagerTarget: false,
			flag: 11,
			reportees_asset_id_list: JSON.stringify(reporteelist),
			is_sip_dynamic_payout: 0,
			startDate,
			endDate,
		})
	}

	const { mutate: fetchMonthWiseAccountData } = useMutation(
		payload => LoadSIPWidgetsAccountcodesMonthWiseData(payload),
		{
			onSuccess: (res, payload) => {
				let accountmonthsdata = []
				setAccountHeaders(res)
				accountmonthsdata = makeAccountData(res, payload.reload)
				setAccountMonthwiseData(prevState => {
					return [...prevState, ...accountmonthsdata]
				})
			},
			onError: async err => {
				console.log(err)
			},
		}
	)

	const loadSIPWidgetsAccountDetails = (reportee, isReload = false) => {
		const {
			selectedPeriodType,
			selectedPeriod,
			selectedSipSegment,
			selectedYear,
		} = queryFilters
		// setAccountAssetId(reportee)
		// if(!!isReload || !!hasMoreData)
		// mutateLoadAccountDetails({
		//   creator: reportee,
		//   segment: 358,
		//   timeline: selectedPeriodType,
		//   period: selectedPeriod,
		//   year: selectedYear,
		//   flag: 0,
		//   is_sip_dynamic_payout: 0,
		//   page: !!isReload ? 0 : page_value
		// },{
		//   onSuccess: data =>{
		//     let accountData = [];
		//     setPageValue(prevState => {
		//       return  prevState + limit_value})
		//     accountData = makeAccountData(data, isReload)
		//     if(!!isReload){
		//       setWidgetAccounts(accountData)
		//       setAccountHeaders(data)
		//       setScrollAtBottom(false)
		//   } else {
		//     // widgetDataRecords = widgetRecords.concat(data);
		//     setWidgetAccounts(prevState => {
		//       return [...prevState, ...accountData]
		//     })
		//   }
		//   if (data?.[0].accountCodes?.length === 0) {
		//     setPageValue(0)
		//     setHasMoreData(false)
		//   }
		//   }
		// })
		mutateLoadAccountList(
			{
				creator: reportee,
				segment: 358,
				timeline: selectedPeriodType,
				period: selectedPeriod,
				year: selectedYear,
				flag: 1,
				is_sip_dynamic_payout: 0,
			},
			{
				onSuccess: data => {
					let accountList = splitAccountList(data, 10)
					for (let index of accountList) {
						fetchMonthWiseAccountData({
							creator: reportee,
							segment: 358,
							timeline: selectedPeriodType,
							period: selectedPeriod,
							year: selectedYear,
							flag: 1,
							is_sip_dynamic_payout: 0,
							customer_account_code: index,
							reload: !accountList.length > 1,
						})
					}
				},
			}
		)
	}

	const splitAccountList = (arr, size) =>
		Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
			arr.slice(i * size, i * size + size)
		)

	const addReporteeToStack = (reportee, isManager) => {
		dashboardDispatch({
			type: isManager
				? dashboardConst.ADD_SIP_RESOURCE
				: dashboardConst.SET_SIP_RESOURCE,
			reportee: reportee,
		})
	}

	const renderSelfHeadings = () => {
		let KpiCodes =
			!!sipWidgetManagerTargetList &&
			!!sipWidgetManagerTargetList[0] &&
			!!sipWidgetManagerTargetList[0].header
				? sipWidgetManagerTargetList[0].header
				: []
		let heads = [
			'Employee Name',
			'Employee Code',
			'Employee Email',
			'Role',
			'Segment',
			...(!!queryFilters &&
			(queryFilters.selectedSipSegment.value === 184 ||
				queryFilters.selectedSipSegment.value === 185 ||
				queryFilters.selectedSipSegment.value === 0)
				? ['Circle', 'Cluster']
				: []),
			...(!!queryFilters &&
			(queryFilters.selectedSipSegment.value === 358 ||
				queryFilters.selectedSipSegment.value === 0)
				? ['Vertical']
				: []),
			...(Object.entries(KpiCodes).length > 0
				? [
						'Employees on SIP',
						'Qualified for SIP',
						'Summarized SIP Penetration %',
						'Summarized SIP Utilization %',
						`Weighted SIP Target vs Acheivement % (${
							!!queryFilters && queryFilters.selectedPeriodType.label
						})`,
				  ]
				: []),
		]

		let individualHeads = [
			'On Target Payout (Default)',
			'Individual OverAll Weighted % for MTD',
			'Individual OverAll Weighted % for QTD',
			'Individual OverAll Weighted % for YTD',
			'Individual Final Payout Amount',
			'Individual Final Payout %',
		]

		individualHeads =
			!!queryFilters.selectedSipSegment &&
			queryFilters.selectedSipSegment.value === 184
				? individualHeads.filter(
						el => el !== 'Individual OverAll Weighted % for YTD'
				  )
				: queryFilters.selectedSipSegment &&
				  (queryFilters.selectedSipSegment.value === 185 ||
						queryFilters.selectedSipSegment.value === 358)
				? individualHeads.filter(
						el => el !== 'Individual OverAll Weighted % for MTD'
				  )
				: individualHeads

		let filteredKpiCodes = Object.entries(KpiCodes)?.map((header, i) => {
			const [key, value] = header
			let removeHeads = Object.entries(value).filter(
				([key]) =>
					![
						'widget_type_code',
						'widget_type_measurement_name',
						'widget_type_measurement_unit',
						'asset_id',
						'employee_email_id',
						'widget_type_name',
						'widget_type_code_sequence_id',
					].includes(key)
			)
			return Object.fromEntries(removeHeads)
		})
		let subHeads = []

		let dynamicHeads =
			!!KpiCodes &&
			Object.entries(KpiCodes).map((header, i) => {
				let isParentKpi = false
				let toolTipName = ''
				const [key, value] = header
				isParentKpi = value['is_parent_kpi']
				toolTipName = value['widget_type_name']
				subHeads = Object.entries(value).filter(
					([key]) =>
						![
							'widget_type_code',
							'widget_type_measurement_name',
							'widget_type_measurement_unit',
							'asset_id',
							'employee_email_id',
							'is_parent_kpi',
							'widget_type_name',
							'widget_type_code_sequence_id',
						].includes(key)
				)
				return (
					<TH
						rowSpan={1}
						classes={isParentKpi ? `parent-column-Theme` : `green-theme`}
						justify={'center'}
						colSpan={subHeads.length}
						key={i + 1}
					>
						<Tooltip
							label={toolTipName}
							fontSize='sm'
							bg='secondary'
							color='black.700'
						>
							<span>
								{' '}
								{value.widget_type_measurement_unit !== 'INR'
									? `${key} ( ${value.widget_type_measurement_unit}; Value In - ${value.widget_type_measurement_name} )`
									: `${key} (Value In - ${value.widget_type_measurement_name})`}
							</span>
						</Tooltip>
					</TH>
				)
			})

		heads = heads.map(item => (
			<TH rowSpan={2} key={item}>
				{item}
			</TH>
		))
		individualHeads = individualHeads.map(item => (
			<TH rowSpan={2} key={item}>
				{item}
			</TH>
		))
		heads = heads.concat(dynamicHeads)
		if (Object.entries(KpiCodes).length > 0) {
			heads = heads.concat(individualHeads)
		}
		let tableHead = (
			<>
				<TR>
					<TH rowSpan={2}>#</TH>
					{heads}
				</TR>
				<TR>
					{!!dynamicHeads &&
						dynamicHeads.length > 0 &&
						filteredKpiCodes?.map(kpi =>
							Object.values(kpi)
								.filter(el => el !== 0 && el !== 1)
								.map((key, i) => (
									<TH
										rowSpan={1}
										classes={
											kpi['is_parent_kpi'] === 1
												? `parent-column-Theme text-Wrap`
												: `green-theme text-Wrap`
										}
										key={key}
									>
										{key}
									</TH>
								))
						)}
				</TR>
			</>
		)
		return tableHead
	}

	const renderHeadings = (isSelf = false) => {
		let KpiCodes =
			!!sipWidgetList && !!sipWidgetList[0] && !!sipWidgetList[0].header
				? sipWidgetList[0].header
				: []
		let heads = [
			isSelf
				? 'Self'
				: isAccountManager
				? !!queryFilters.selectedSipSegment &&
				  queryFilters.selectedSipSegment.value === 184
					? 'Channel Name'
					: 'Account Name'
				: 'Employee Name',
			isAccountManager
				? !!queryFilters.selectedSipSegment &&
				  queryFilters.selectedSipSegment.value === 184
					? 'Channel Code'
					: 'Account Code'
				: 'Employee Code',
			'Employee Email',
			'Role',
			'Segment',
			...(!!queryFilters &&
			(queryFilters.selectedSipSegment.value === 184 ||
				queryFilters.selectedSipSegment.value === 185 ||
				queryFilters.selectedSipSegment.value === 0)
				? ['Circle', 'Cluster']
				: []),
			...(!!queryFilters &&
			(queryFilters.selectedSipSegment.value === 358 ||
				queryFilters.selectedSipSegment.value === 0)
				? ['Vertical']
				: []),
			...(Object.entries(KpiCodes).length > 0
				? [
						'Employees on SIP',
						'Qualified for SIP',
						'Summarized SIP Penetration %',
						'Summarized SIP Utilization %',
						`Weighted SIP Target vs Acheivement % (${
							!!queryFilters && queryFilters.selectedPeriodType.label
						})`,
						'Months',
				  ]
				: []),
		]
		let individualHeads = [
			'On Target Payout (Default)',
			'Individual OverAll Weighted % for MTD',
			'Individual OverAll Weighted % for QTD',
			'Individual OverAll Weighted % for YTD',
			'Individual Final Payout Amount',
			'Individual Final Payout %',
		]

		individualHeads =
			!!queryFilters.selectedSipSegment &&
			queryFilters.selectedSipSegment.value === 184
				? individualHeads.filter(
						el => el !== 'Individual OverAll Weighted % for YTD'
				  )
				: queryFilters.selectedSipSegment &&
				  (queryFilters.selectedSipSegment.value === 185 ||
						queryFilters.selectedSipSegment.value === 358)
				? individualHeads.filter(
						el => el !== 'Individual OverAll Weighted % for MTD'
				  )
				: individualHeads

		let filteredKpiCodes = Object.entries(KpiCodes)?.map((header, i) => {
			const [key, value] = header
			let removeHeads = Object.entries(value).filter(
				([key]) =>
					![
						'widget_type_code',
						'widget_type_measurement_name',
						'widget_type_measurement_unit',
						'asset_id',
						'employee_email_id',
						'widget_type_name',
						'widget_type_code_sequence_id',
					].includes(key)
			)
			return Object.fromEntries(removeHeads)
		})
		let subHeads = []
		let isParentKpi = false
		let toolTipName = ''
		let dynamicHeads =
			!!KpiCodes &&
			Object.entries(KpiCodes).map((header, i) => {
				const [key, value] = header
				isParentKpi = value['is_parent_kpi']
				toolTipName = value['widget_type_name']
				subHeads = Object.entries(value).filter(
					([key]) =>
						![
							'widget_type_code',
							'widget_type_measurement_name',
							'widget_type_measurement_unit',
							'asset_id',
							'employee_email_id',
							'is_parent_kpi',
							'widget_type_name',
							'widget_type_code_sequence_id',
						].includes(key)
				)
				return (
					<TH
						rowSpan={1}
						classes={isParentKpi ? `parent-column-Theme` : `green-theme`}
						justify={'center'}
						colSpan={subHeads.length}
						key={i + 1}
					>
						<Tooltip
							label={toolTipName}
							fontSize='sm'
							bg='secondary'
							color='black.700'
						>
							<span>
								{' '}
								{value.widget_type_measurement_unit !== 'INR'
									? `${key} ( ${value.widget_type_measurement_unit}; Value In - ${value.widget_type_measurement_name} )`
									: `${key} (Value In - ${value.widget_type_measurement_name})`}
							</span>
						</Tooltip>
					</TH>
				)
			})

		heads = heads.map(item => (
			<TH rowSpan={2} key={item}>
				{item}
			</TH>
		))
		individualHeads = individualHeads.map(item => (
			<TH rowSpan={2} key={item}>
				{item}
			</TH>
		))
		heads = heads.concat(dynamicHeads)
		if (Object.entries(KpiCodes).length > 0) {
			heads = heads.concat(individualHeads)
		}
		let tableHead = (
			<>
				<TR>
					<TH rowSpan={2}>#</TH>
					{heads}
				</TR>
				<TR>
					{!!dynamicHeads &&
						dynamicHeads.length > 0 &&
						filteredKpiCodes?.map(kpi =>
							Object.values(kpi)
								.filter(el => el !== 0 && el !== 1)
								.map((key, i) => (
									<TH
										rowSpan={1}
										classes={
											kpi['is_parent_kpi'] === 1
												? `parent-column-Theme text-Wrap`
												: `green-theme text-Wrap`
										}
										key={key}
									>
										{key}
									</TH>
								))
						)}
				</TR>
			</>
		)
		return tableHead
	}

	const getColumnHeader = (widget_type_code, key, self = false) => {
		let kpiCodeHeaders
		if (!self) {
			kpiCodeHeaders =
				!!sipWidgetList && !!sipWidgetList[0] && !!sipWidgetList[0].header
					? sipWidgetList[0].header
					: []
		} else {
			kpiCodeHeaders =
				!!sipWidgetManagerTargetList &&
				!!sipWidgetManagerTargetList[0] &&
				!!sipWidgetManagerTargetList[0].header
					? sipWidgetManagerTargetList[0].header
					: []
		}
		let headers = kpiCodeHeaders[widget_type_code]
		let columnnName = headers[key]
		return columnnName
	}

	const setManagerKpiResponseBody = () => {
		let bodyResponseData, headerResponseData, assetList
		bodyResponseData =
			!!sipWidgetManagerTargetList && !!sipWidgetManagerTargetList[0]?.body
				? sipWidgetManagerTargetList[0].body
				: []
		headerResponseData =
			!!sipWidgetManagerTargetList && !!sipWidgetManagerTargetList[0]?.header
				? sipWidgetManagerTargetList[0].header
				: []
		assetList = managerDetails?.map(
			el => el.asset_id + '::' + el.workforce_tag_id
		)

		let kpiCodes = !!headerResponseData && Object.keys(headerResponseData)
		let kpiCodeWiseHeaders = getKpiCodeWiseHeaders(true)
		let userMap = new Map()
		let userKPICodeData = new Map()
		let newKpiCodeNameMap = new Map()

		for (let index in bodyResponseData) {
			userKPICodeData = bodyResponseData[index]
			let asset_id =
				userKPICodeData.asset_id + '::' + userKPICodeData.workforce_tag_id
			newKpiCodeNameMap.set(
				userKPICodeData.widget_type_code,
				userKPICodeData.widget_type_name
			)
			let kpiCode = userKPICodeData.widget_type_code

			if (userMap.has(asset_id)) {
				let kpiCodeMap = userMap.get(asset_id)
				if (!kpiCodeMap.has(kpiCode)) {
					kpiCodeMap.set(kpiCode, userKPICodeData)
				}
			} else {
				let kpiCodeMap = new Map()
				kpiCodeMap.set(kpiCode, userKPICodeData)
				userMap.set(asset_id, kpiCodeMap)
			}
		}

		let userWiseFinalExcelDataMap = new Map()
		let nonKpiCodeArray = []

		userMap.forEach((value, asset_id) => {
			let columnExcelDataArray = []
			for (let index in kpiCodes) {
				let kpiCode = kpiCodes[index]
				let kpiCoderHeaderData = headerResponseData[kpiCode]
				let kpiCodeHeders = kpiCodeWiseHeaders[kpiCode]
				let userKPICodeDataMap = userMap.get(asset_id)
				let userDetails = userKPICodeDataMap.get(kpiCode)
				//console.log("key", userDetails , kpiCodeHeders);
				if (userDetails !== undefined && kpiCodeHeders !== undefined) {
					for (let userDetailsKey of kpiCodeHeders) {
						let excelKey =
							headerResponseData[kpiCode].widget_type_name +
							'_' +
							kpiCoderHeaderData[userDetailsKey]
						//   console.log(excelKey)
						let obj = {}
						obj[excelKey] = userDetails[userDetailsKey]
						columnExcelDataArray.push(obj)
						obj = {}
						obj[excelKey] = 'NA'
						nonKpiCodeArray.push(obj)
					}
				} else {
					for (let userDetailsKey of kpiCodeHeders) {
						let excelKey =
							headerResponseData[kpiCode].widget_type_name +
							'_' +
							kpiCoderHeaderData[userDetailsKey]
						let obj = {}
						obj[excelKey] = 'NA'
						nonKpiCodeArray.push(obj)
						columnExcelDataArray.push(obj)
					}
				}
			}

			return userWiseFinalExcelDataMap.set(asset_id, columnExcelDataArray)
		})
		assetList.forEach(asset_id => {
			if (!userMap.has(asset_id)) {
				userWiseFinalExcelDataMap.set(asset_id, nonKpiCodeArray)
			}
		})

		return userWiseFinalExcelDataMap
	}

	const setReporteeKpiResponseBody = () => {
		let bodyResponseData, headerResponseData, assetList, months
		bodyResponseData =
			!!sipWidgetList && !!sipWidgetList[0]?.body ? sipWidgetList[0].body : []
		headerResponseData =
			!!sipWidgetList && !!sipWidgetList[0]?.header
				? sipWidgetList[0].header
				: []
		assetList = reporteeList?.map(el => el.asset_id)
		months =
			!!sipWidgetList && !!sipWidgetList[0]?.body ? sipWidgetList[0].months : []

		let kpiCodes = !!headerResponseData && Object.keys(headerResponseData)
		let kpiCodeWiseHeaders = getKpiCodeWiseHeaders()
		let userMap = new Map()
		let userKPICodeData = new Map()
		let newKpiCodeNameMap = new Map()

		for (let index in bodyResponseData) {
			userKPICodeData = bodyResponseData[index]
			let asset_id = userKPICodeData.asset_id
			newKpiCodeNameMap.set(
				userKPICodeData.widget_type_code,
				userKPICodeData.widget_type_name
			)
			let kpiCode = userKPICodeData.widget_type_code

			if (userMap.has(asset_id)) {
				let kpiCodeMap = userMap.get(asset_id)
				if (!kpiCodeMap.has(kpiCode)) {
					kpiCodeMap.set(kpiCode, userKPICodeData)
				}
			} else {
				let kpiCodeMap = new Map()
				kpiCodeMap.set(kpiCode, userKPICodeData)
				userMap.set(asset_id, kpiCodeMap)
			}
		}

		let userWiseFinalExcelDataMap = new Map()
		let nonKpiCodeArray = []
		let naArray = []
		for (let month of months) {
			naArray.push('NA')
		}

		userMap.forEach((value, asset_id) => {
			let columnExcelDataArray = []
			for (let index in kpiCodes) {
				let kpiCode = kpiCodes[index]
				let kpiCoderHeaderData = headerResponseData[kpiCode]
				let kpiCodeHeders = kpiCodeWiseHeaders[kpiCode]
				let userKPICodeDataMap = userMap.get(asset_id)
				let userDetails = userKPICodeDataMap.get(kpiCode)
				//console.log("key", userDetails , kpiCodeHeders);
				if (userDetails !== undefined && kpiCodeHeders !== undefined) {
					for (let userDetailsKey of kpiCodeHeders) {
						let excelKey =
							headerResponseData[kpiCode].widget_type_name +
							'_' +
							kpiCoderHeaderData[userDetailsKey]
						//   console.log(excelKey)
						let obj = {}
						obj[excelKey] = userDetails[userDetailsKey]
						columnExcelDataArray.push(obj)
						obj = {}
						obj[excelKey] = naArray
						nonKpiCodeArray.push(obj)
					}
				} else {
					for (let userDetailsKey of kpiCodeHeders) {
						let excelKey =
							headerResponseData[kpiCode].widget_type_name +
							'_' +
							kpiCoderHeaderData[userDetailsKey]
						let obj = {}
						obj[excelKey] = naArray
						nonKpiCodeArray.push(obj)
						columnExcelDataArray.push(obj)
					}
				}
			}

			return userWiseFinalExcelDataMap.set(asset_id, columnExcelDataArray)
		})
		assetList.forEach(asset_id => {
			if (!userMap.has(asset_id)) {
				userWiseFinalExcelDataMap.set(asset_id, nonKpiCodeArray)
			}
		})

		return userWiseFinalExcelDataMap
	}

	const getKpiCodeWiseHeaders = (isSelf = false) => {
		let response = {}
		let subHeads = []
		if (!isSelf) {
			let reporteeKpiHeaders =
				!!sipWidgetList && !!sipWidgetList[0]?.header
					? sipWidgetList[0].header
					: []
			Object.entries(reporteeKpiHeaders).map((header, i) => {
				const [key, value] = header
				subHeads = Object.keys(value).filter(
					el =>
						![
							'widget_type_code',
							'widget_type_measurement_name',
							'widget_type_measurement_unit',
							'asset_id',
							'employee_email_id',
							'is_parent_kpi',
							'widget_type_name',
							'widget_type_code_sequence_id',
						].includes(el)
				)
				response[key] = Object.values(subHeads)
			})
		} else {
			let managerKpiHeaders =
				!!sipWidgetManagerTargetList && !!sipWidgetManagerTargetList[0]?.header
					? sipWidgetManagerTargetList[0].header
					: []
			Object.entries(managerKpiHeaders).map((header, i) => {
				const [key, value] = header
				subHeads = Object.keys(value).filter(
					el =>
						![
							'widget_type_code',
							'widget_type_measurement_name',
							'widget_type_measurement_unit',
							'asset_id',
							'employee_email_id',
							'is_parent_kpi',
							'widget_type_name',
							'widget_type_code_sequence_id',
						].includes(el)
				)
				response[key] = Object.values(subHeads)
			})
		}
		return response
	}

	const getAccountCodewiseHeaders = accountDetails => {
		let response = {}
		let subHeads = []
		let accountKpiHeaders =
			!!accountDetails && !!accountDetails[0]?.header
				? accountDetails[0].header
				: []
		Object.entries(accountKpiHeaders).map((header, i) => {
			const [key, value] = header
			subHeads = Object.keys(value).filter(
				el =>
					![
						'widget_type_code',
						'widget_type_measurement_name',
						'widget_type_measurement_unit',
						'asset_id',
						'employee_email_id',
						'is_parent_kpi',
						'widget_type_name',
						'widget_type_code_sequence_id',
						'customer_account_code',
						'customer_account_name',
					].includes(el)
			)
			response[key] = Object.values(subHeads)
		})
		return response
	}

	const setAccountKpiResponseBody = accountDetails => {
		let bodyResponseData =
			!!accountDetails && !!accountDetails[0]?.body
				? accountDetails[0].body
				: []
		let headerResponseData =
			!!accountDetails && !!accountDetails[0]?.header
				? accountDetails[0].header
				: []
		let months =
			!!accountDetails && !!accountDetails[0]?.header
				? accountDetails[0].months
				: []
		let kpiCodes = Object.keys(headerResponseData)
		let kpiCodeWiseHeaders = getAccountCodewiseHeaders(accountDetails)
		let userMap = new Map()
		let userKPICodeData = new Map()

		for (let index in bodyResponseData) {
			userKPICodeData = bodyResponseData[index]
			let customer_account_code =
				userKPICodeData.customer_account_code +
				'::' +
				userKPICodeData.workforce_tag_name
			let kpiCode = userKPICodeData.widget_type_code

			if (userMap.has(customer_account_code)) {
				let kpiCodeMap = userMap.get(customer_account_code)
				if (!kpiCodeMap.has(kpiCode)) {
					kpiCodeMap.set(kpiCode, userKPICodeData)
				}
			} else {
				let kpiCodeMap = new Map()
				kpiCodeMap.set(kpiCode, userKPICodeData)
				userMap.set(customer_account_code, kpiCodeMap)
			}
		}

		let nonKpiCodeArray = []
		let naArray = []
		for (let month of months) {
			naArray.push('NA')
		}
		//let userWiseFinalDataMap = new Map();
		let userWiseFinalExcelDataMap = new Map()
		userMap.forEach((value, customer_account_code) => {
			let columnDataArray = []
			let columnExcelDataArray = []
			for (let index in kpiCodes) {
				let kpiCode = kpiCodes[index]
				let kpiCoderHeaderData = headerResponseData[kpiCode]
				let kpiCodeHeders = kpiCodeWiseHeaders[kpiCode]
				// console.log("key", headerResponseData[kpiCode].widget_type_name);
				let userKPICodeDataMap = userMap.get(customer_account_code)
				let userDetails = userKPICodeDataMap.get(kpiCode)
				if (userDetails !== undefined && kpiCodeHeders !== undefined) {
					for (let userDetailsKey of kpiCodeHeders) {
						let excelKey =
							headerResponseData[kpiCode].widget_type_name +
							'_' +
							kpiCoderHeaderData[userDetailsKey]
						//   console.log(excelKey)
						let obj = {}
						obj[excelKey] = userDetails[userDetailsKey]
						columnExcelDataArray.push(obj)
						columnDataArray.push(userDetails[userDetailsKey])
						obj = {}
						obj[excelKey] = naArray
						nonKpiCodeArray.push(obj)
					}
				} else {
					for (let userDetailsKey of kpiCodeHeders) {
						let excelKey =
							headerResponseData[kpiCode].widget_type_name +
							'_' +
							kpiCoderHeaderData[userDetailsKey]
						let obj = {}
						obj[excelKey] = naArray
						columnExcelDataArray.push(obj)
						columnDataArray.push(naArray)
					}
				}
			}
			// else
			// return userWiseFinalDataMap.set(customer_account_code, columnDataArray);
			return userWiseFinalExcelDataMap.set(
				customer_account_code,
				columnExcelDataArray
			)
		})

		return userWiseFinalExcelDataMap
	}

	const makeTableData = () => {
		let data = []
		let _arr = reporteeList || []
		let reporteeKpiHeaders =
			!!sipWidgetList && !!sipWidgetList[0]?.header
				? sipWidgetList[0].header
				: []
		let months =
			sipWidgetList && !!sipWidgetList[0] && !!sipWidgetList[0].months
				? sipWidgetList[0].months
				: []
		let reporteesSubHeadData = setReporteeKpiResponseBody()

		let parentHead = Object.values(reporteesSubHeadData)

		_arr?.forEach((item, i) => {
			let obj = {}
			if (!!item.operating_asset_first_name) {
				obj['S.no'] = i + 1
				obj['Employee Name'] = item.operating_asset_first_name
				obj['Employee Code'] = item.operating_asset_customer_unique_id
				obj['Employee Email'] = item.operating_asset_email_id
				obj['Role'] = item.asset_type_name
				obj['Segment'] = item.workforce_tag_name
				if (
					!!queryFilters &&
					(queryFilters.selectedSipSegment.value === 184 ||
						queryFilters.selectedSipSegment.value === 185 ||
						queryFilters.selectedSipSegment.value === 0)
				) {
					obj['Circle'] = item.account_name
					obj['Cluster'] = item.cluster_tag_name || '-'
				}
				if (
					!!queryFilters &&
					(queryFilters.selectedSipSegment.value === 358 ||
						queryFilters.selectedSipSegment.value === 0)
				) {
					obj['Vertical'] = item.asset_vertical_name
				}
				if (
					!!reporteeKpiHeaders &&
					Object.entries(reporteeKpiHeaders).length > 0
				) {
					obj['Employees on SIP'] = !!item.employees_on_sip
						? item.employees_on_sip
						: '1'
					obj['Qualified for SIP'] = !!item.qualified_for_sip
						? item.qualified_for_sip
						: '0'
					obj['Summarized SIP Penetration %'] = !!item.percentage_penetration
						? item.percentage_penetration.toFixed(2) + '%'
						: '-'
					obj['Summarized SIP Utilization %'] = !!item.summary_utilization
						? item.summary_utilization.toFixed(2) + '%'
						: '-'
					if (
						!!queryFilters &&
						queryFilters.selectedPeriodType.value === 'QTD'
					) {
						obj[
							`Weighted SIP Target vs Acheivement % (${
								!!queryFilters && queryFilters.selectedPeriodType.label
							})`
						] = !!item.summary_weighted_sip_target_vs_achievement_qtd
							? item.summary_weighted_sip_target_vs_achievement_qtd.toFixed(2) +
							  ' %'
							: '-'
					} else if (
						!!queryFilters &&
						queryFilters.selectedPeriodType.value === 'MTD'
					) {
						obj[
							`Weighted SIP Target vs Acheivement % (${
								!!queryFilters && queryFilters.selectedPeriodType.label
							})`
						] = !!item.summary_weighted_sip_target_vs_achievement_mtd
							? item.summary_weighted_sip_target_vs_achievement_mtd.toFixed(2) +
							  ' %'
							: '-'
					} else if (
						!!queryFilters &&
						queryFilters.selectedPeriodType.value === 'YTD'
					) {
						obj[
							`Weighted SIP Target vs Acheivement % (${
								!!queryFilters && queryFilters.selectedPeriodType.label
							})`
						] = !!item.summary_weighted_sip_target_vs_achievement_ytd
							? item.summary_weighted_sip_target_vs_achievement_ytd.toFixed(2) +
							  ' %'
							: '-'
					} else {
						obj[
							`Weighted SIP Target vs Acheivement % (${
								!!queryFilters && queryFilters.selectedPeriodType.label
							})`
						] = !!item.summary_weighted_sip_target_vs_achievement_ytd
							? item.summary_weighted_sip_target_vs_achievement_ytd.toFixed(2) +
							  ' %'
							: '-'
					}
					obj['Months'] = months

					let kpiCodeHead =
						!!parentHead && parentHead.length > 0
							? parentHead?.filter(el => item.asset_id === el.asset_id)
							: []

					let reporteeKpiData = !!reporteesSubHeadData.get(item.asset_id)
						? Array.from(reporteesSubHeadData.get(item.asset_id))
						: []

					let reporteePredictionValues =
						!!reporteeKpiData && reporteeKpiData.length > 0
							? Object.assign(...reporteeKpiData)
							: {}
					obj = { ...obj, ...reporteePredictionValues }

					obj['On Target Payout (Default)'] =
						(!!item.payout_target && item.payout_target) || 0
					if (
						!!queryFilters &&
						(queryFilters.selectedSipSegment.value === 184 ||
							queryFilters.selectedSipSegment.value === 0)
					) {
						obj['Individual OverAll Weighted % for MTD'] =
							(!!item.overall_ach_mtd &&
								item.overall_ach_mtd.toFixed(2) + ' %') ||
							'0.00 %'
					}
					obj['Individual OverAll Weighted % for QTD'] =
						(!!item.overall_ach_qtd &&
							item.overall_ach_qtd.toFixed(2) + ' %') ||
						'0.00 %'
					if (
						!!queryFilters &&
						(queryFilters.selectedSipSegment.value === 185 ||
							queryFilters.selectedSipSegment.value === 0 ||
							queryFilters.selectedSipSegment.value === 358)
					) {
						obj['Individual OverAll Weighted % for YTD'] =
							(!!item.overall_ach_ytd &&
								item.overall_ach_ytd.toFixed(2) + ' %') ||
							'0.00 %'
					}
					obj['Individual Final Payout Amount'] =
						(!!item.final_payout_amount && item.final_payout_amount) || 0
					obj['Individual Final Payout %'] =
						(!!item.final_payout_percentage &&
							item.final_payout_percentage.toFixed(2) + ' %') ||
						'0.00 %'
				}
			}
			// if (isAccountManager) {
			//   ;[9, 10, 11, 12, 13, 14].forEach(x => {
			//     obj[x] = '-'
			//   })
			// }

			data.push(obj)
		})

		return [data, _arr]
	}

	const renderData = () => {
		let [data, _arr] = makeTableData()
		return (
			!!data &&
			data.map((item, i) => (
				<TR key={i}>
					{Object.values(item).map((val, j) =>
						j === 1 ? (
							<TD
								key={j}
								onClickHandler={() => {
									_arr[i].label = _arr[i].operating_asset_first_name
									dashboardDispatch({
										type: dashboardConst.ADD_ACTIVE_FILTER_OPTION,
										activeFilterOption: _arr[i],
									})
									loadResourceForAsset(_arr[i])
								}}
								classes={`text-bold green-theme cursor-pointer`}
							>
								{val}
							</TD>
						) : Array.isArray(val) ? (
							<TD>
								{Object.values(val).map((str, k) => (
									<TR>
										<TD key={k}>{str}</TD>
									</TR>
								))}
							</TD>
						) : (
							<TD key={j}>{val}</TD>
						)
					)}
				</TR>
			))
		)
	}

	const renderAccountData = () => {
		let data = accountMonthwiseData
		return (
			!!data &&
			data.map((item, i) => (
				<TR key={i}>
					<TD>{i + 1}</TD>
					{Object.values(item).map((val, j) =>
						j === 0 ? (
							<TD key={j} classes={`text-bold green-theme cursor-pointer`}>
								{val}
							</TD>
						) : Array.isArray(val) ? (
							<TD>
								{Object.values(val).map((str, k) => (
									<TR>
										<TD key={k}>{str}</TD>
									</TR>
								))}
							</TD>
						) : (
							<TD key={j}>{val}</TD>
						)
					)}
				</TR>
			))
		)
	}

	const renderAccountHeadings = () => {
		let heads = ['Account Code', 'Account Name', 'Segment', 'Months']
		//  let individualHeads = ['On Target Payout (Default)',
		//     'Individual OverAll Weighted % for MTD',
		//     'Individual OverAll Weighted % for QTD',
		//     'Individual OverAll Weighted % for YTD',
		//     'Individual Final Payout Amount',
		//     'Individual Final Payout %']

		// individualHeads = !!queryFilters.selectedSipSegment && queryFilters.selectedSipSegment.value === 184 ?
		// individualHeads.filter(el => el !=='Individual OverAll Weighted % for YTD') : queryFilters.selectedSipSegment && (queryFilters.selectedSipSegment.value === 185 || queryFilters.selectedSipSegment.value === 358) ?
		// individualHeads.filter(el => el !=='Individual OverAll Weighted % for MTD') : individualHeads

		let KpiCodes =
			!!accountHeaders && !!accountHeaders[0]?.header
				? accountHeaders[0]?.header
				: []

		let filteredKpiCodes = Object.entries(KpiCodes)?.map((header, i) => {
			const [key, value] = header
			let removeHeads = Object.entries(value).filter(
				([key]) =>
					![
						'widget_type_code',
						'widget_type_measurement_name',
						'widget_type_measurement_unit',
						'asset_id',
						'employee_email_id',
						'widget_type_name',
						'widget_type_code_sequence_id',
						'customer_account_code',
						'customer_account_name',
					].includes(key)
			)
			return Object.fromEntries(removeHeads)
		})
		let subHeads = []
		let isParentKpi = false
		let toolTipName = ''
		let dynamicHeads =
			!!KpiCodes &&
			Object.entries(KpiCodes).map((header, i) => {
				const [key, value] = header
				isParentKpi = value['is_parent_kpi']
				toolTipName = value['widget_type_name'] || 'NA'
				subHeads = Object.entries(value).filter(
					([key]) =>
						![
							'widget_type_code',
							'widget_type_measurement_name',
							'widget_type_measurement_unit',
							'asset_id',
							'employee_email_id',
							'is_parent_kpi',
							'widget_type_name',
							'widget_type_code_sequence_id',
							'customer_account_code',
							'customer_account_name',
						].includes(key)
				)
				return (
					<TH
						rowSpan={1}
						classes={isParentKpi ? `parent-column-Theme` : `green-theme`}
						justify={'center'}
						colSpan={subHeads.length}
						key={i + 1}
					>
						<Tooltip
							label={toolTipName}
							fontSize='sm'
							bg='secondary'
							color='black.700'
						>
							<span>
								{' '}
								{value.widget_type_measurement_unit !== 'INR'
									? `${key} ( ${value.widget_type_measurement_unit}; Value In - ${value.widget_type_measurement_name} )`
									: `${key} (Value In - ${value.widget_type_measurement_name})`}
							</span>
						</Tooltip>
					</TH>
				)
			})

		heads = heads.map(item => (
			<TH rowSpan={2} key={item}>
				{item}
			</TH>
		))
		// individualHeads = individualHeads.map(item => (
		//   <TH rowSpan={2} key={item}>
		//     {item}
		//   </TH>
		// ))
		heads = heads.concat(dynamicHeads)
		//  heads = heads.concat(individualHeads)
		let tableHead = (
			<>
				<TR>
					<TH rowSpan={2}>#</TH>
					{heads}
				</TR>
				<TR>
					{!!dynamicHeads &&
						dynamicHeads.length > 0 &&
						filteredKpiCodes?.map(kpi =>
							Object.values(kpi)
								.filter(el => el !== 0 && el !== 1)
								.map((key, i) => (
									<TH
										rowSpan={1}
										classes={
											kpi['is_parent_kpi'] === 1
												? `parent-column-Theme text-Wrap`
												: `green-theme text-Wrap`
										}
										key={key}
									>
										{key}
									</TH>
								))
						)}
				</TR>
			</>
		)
		return tableHead
	}

	const makeSelfDynamicData = () => {
		let obj = {}
		let managerKpiData =
			!!sipWidgetManagerTargetList && !!sipWidgetManagerTargetList[0]?.body
				? sipWidgetManagerTargetList[0].body
				: []
		let data = Object.values(managerKpiData)
		let filteredPrediction = []
		data.map(item => {
			delete item.widget_type_measurement_name
			delete item.widget_type_measurement_unit
			Object.entries(item).map(([key, value]) => {
				let keyName =
					item['widget_type_name'] +
					'_' +
					getColumnHeader(item['widget_type_code'], key, true)
				if (
					key !== 'widget_type_code' &&
					key !== 'asset_id' &&
					key !== 'employee_email_id' &&
					key !== 'is_parent_kpi' &&
					key !== 'widget_type_name' &&
					key !== 'widget_type_code_sequence_id'
				)
					filteredPrediction.push({ [keyName]: value })
			})
			return filteredPrediction
		})
		obj =
			!!filteredPrediction && filteredPrediction.length > 0
				? Object.assign(...filteredPrediction)
				: {}

		return obj
	}

	const makeAccountData = (accountDetails, isReload) => {
		let data = []
		let _arr =
			!!accountDetails && !!accountDetails[0]?.accountCodes
				? accountDetails[0].accountCodes
				: []
		let accountKpiHeaders =
			!!accountDetails && !!accountDetails[0]?.header
				? accountDetails[0].header
				: []
		let months =
			!!accountDetails && !!accountDetails[0] && !!accountDetails[0].months
				? accountDetails[0].months
				: []
		let accountsSubHeadData = setAccountKpiResponseBody(accountDetails)

		let parentHead = Object.values(accountsSubHeadData)
		_arr?.forEach((item, i) => {
			let obj = {}
			// obj['S.no'] = !isReload ? widgetAccounts.length + i + 1 : i + 1
			obj['Account Code'] = item.customer_account_code
			obj['Account Name'] = item.customer_account_name

			obj['Segment'] = item.workforce_tag_name || '-'

			let kpiCodeHead =
				!!parentHead && parentHead.length > 0
					? parentHead?.filter(
							el => item.customer_account_code === el.customer_account_code
					  )
					: []
			if (!!accountKpiHeaders && Object.entries(accountKpiHeaders).length > 0) {
				obj['Months'] = months
				let accountKpiData = !!accountsSubHeadData.get(
					item.customer_account_code + '::' + item.workforce_tag_name
				)
					? Array.from(
							accountsSubHeadData.get(
								item.customer_account_code + '::' + item.workforce_tag_name
							)
					  )
					: []

				let accountPredictionValues =
					!!accountKpiData && accountKpiData.length > 0
						? Object.assign(...accountKpiData)
						: {}
				obj = { ...obj, ...accountPredictionValues }
			}
			// obj['On Target Payout (Default)']= (!!item.payout_target && item.payout_target.toFixed(2)) || 0
			// if(!!queryFilters && (queryFilters.selectedSipSegment.value === 184 || queryFilters.selectedSipSegment.value === 0)){
			// obj['Individual OverAll Weighted % for MTD']= (!!item.overall_ach_mtd && item.overall_ach_mtd.toFixed(2) + ' %') || '0.00 %'
			// }
			// obj['Individual OverAll Weighted % for QTD']= (!!item.overall_ach_qtd && item.overall_ach_qtd.toFixed(2) + ' %') || '0.00 %'
			// if(!!queryFilters && (queryFilters.selectedSipSegment.value === 185 || queryFilters.selectedSipSegment.value === 0 || queryFilters.selectedSipSegment.value === 358)){
			// obj['Individual OverAll Weighted % for YTD']= (!!item.overall_ach_ytd && item.overall_ach_ytd.toFixed(2) + ' %') || '0.00 %'
			// }
			// obj['Individual Final Payout Amount']= (!!item.final_payout_amount && item.final_payout_amount.toFixed(2)) || 0
			// obj['Individual Final Payout %']= (!!item.final_payout_percentage && item.final_payout_percentage.toFixed(2) + ' %') || '0.00 %'

			// if (isAccountManager) {
			//   ;[9, 10, 11, 12, 13, 14].forEach(x => {
			//     obj[x] = '-'
			//   })
			// }

			data.push(obj)
		})
		return data //[data, _arr]
	}

	const makeSelfData = () => {
		let data = []

		let _arr = managerDetails || []
		let managerKpiHeaders =
			!!sipWidgetManagerTargetList && !!sipWidgetManagerTargetList[0]?.header
				? sipWidgetManagerTargetList[0].header
				: []
		let managersSubHeadData =
			!!managerKpiHeaders && Object.entries(managerKpiHeaders).length > 0
				? setManagerKpiResponseBody()
				: []
		let parentHead = Object.values(managersSubHeadData)
		_arr?.forEach((item, i) => {
			let obj = {}
			if (!!item.operating_asset_first_name) {
				obj['S.no'] = i + 1
				obj['Self'] = item.operating_asset_first_name
				obj['Employee Code'] = item.operating_asset_customer_unique_id
				obj['Employee Email'] = item.operating_asset_email_id
				obj['Role'] = item.asset_type_name
				obj['Segment'] = item.workforce_tag_name
				if (
					!!queryFilters &&
					(queryFilters.selectedSipSegment.value === 184 ||
						queryFilters.selectedSipSegment.value === 185 ||
						queryFilters.selectedSipSegment.value === 0)
				) {
					obj['Circle'] = item.account_name
					obj['Cluster'] = item.cluster_tag_name || '-'
				}
				if (
					!!queryFilters &&
					(queryFilters.selectedSipSegment.value === 358 ||
						queryFilters.selectedSipSegment.value === 0)
				) {
					obj['Vertical'] = item.asset_vertical_name
				}
				if (
					!!managerKpiHeaders &&
					Object.entries(managerKpiHeaders).length > 0
				) {
					obj['Employees on SIP'] = !!item.employees_on_sip
						? item.employees_on_sip
						: '1'
					obj['Qualified for SIP'] = !!item.qualified_for_sip
						? item.qualified_for_sip
						: '0'
					obj['Summarized SIP Penetration %'] =
						!!item.asset_flag_frontline &&
						item.asset_flag_frontline !== 1 &&
						!!item.percentage_penetration
							? item.percentage_penetration.toFixed(2) + '%'
							: '-'
					obj['Summarized SIP Utilization %'] =
						!!item.asset_flag_frontline &&
						item.asset_flag_frontline !== 1 &&
						!!item.summary_utilization
							? item.summary_utilization.toFixed(2) + '%'
							: '-'
					if (
						!!queryFilters &&
						queryFilters.selectedPeriodType.value === 'QTD'
					) {
						obj[
							`Weighted SIP Target vs Acheivement % (${
								!!queryFilters && queryFilters.selectedPeriodType.label
							})`
						] = !!item.summary_weighted_sip_target_vs_achievement_qtd
							? item.summary_weighted_sip_target_vs_achievement_qtd.toFixed(2) +
							  ' %'
							: '-'
					} else if (
						!!queryFilters &&
						queryFilters.selectedPeriodType.value === 'MTD'
					) {
						obj[
							`Weighted SIP Target vs Acheivement % (${
								!!queryFilters && queryFilters.selectedPeriodType.label
							})`
						] = !!item.summary_weighted_sip_target_vs_achievement_mtd
							? item.summary_weighted_sip_target_vs_achievement_mtd.toFixed(2) +
							  ' %'
							: '-'
					} else if (
						!!queryFilters &&
						queryFilters.selectedPeriodType.value === 'YTD'
					) {
						obj[
							`Weighted SIP Target vs Acheivement % (${
								!!queryFilters && queryFilters.selectedPeriodType.label
							})`
						] = !!item.summary_weighted_sip_target_vs_achievement_ytd
							? item.summary_weighted_sip_target_vs_achievement_ytd.toFixed(2) +
							  ' %'
							: '-'
					} else {
						obj[
							`Weighted SIP Target vs Acheivement % (${
								!!queryFilters && queryFilters.selectedPeriodType.label
							})`
						] = !!item.summary_weighted_sip_target_vs_achievement_ytd
							? item.summary_weighted_sip_target_vs_achievement_ytd.toFixed(2) +
							  ' %'
							: '-'
					}

					let kpiCodeHead =
						!!parentHead && parentHead.length > 0
							? parentHead?.filter(el => item.asset_id === el.asset_id)
							: []

					let managerKpiData = !!managersSubHeadData.get(
						item.asset_id + '::' + item.workforce_tag_id
					)
						? Array.from(
								managersSubHeadData.get(
									item.asset_id + '::' + item.workforce_tag_id
								)
						  )
						: []

					let managerPredictionValues =
						!!managerKpiData && managerKpiData.length > 0
							? Object.assign(...managerKpiData)
							: {}
					obj = { ...obj, ...managerPredictionValues }

					obj['On Target Payout (Default)'] =
						(!!item.payout_target && item.payout_target) || 0
					if (
						!!queryFilters &&
						(queryFilters.selectedSipSegment.value === 184 ||
							queryFilters.selectedSipSegment.value === 0)
					) {
						obj['Individual OverAll Weighted % for MTD'] =
							(!!item.overall_ach_mtd &&
								item.overall_ach_mtd.toFixed(2) + ' %') ||
							'0.00 %'
					}
					obj['Individual OverAll Weighted % for QTD'] =
						(!!item.overall_ach_qtd &&
							item.overall_ach_qtd.toFixed(2) + ' %') ||
						'0.00 %'
					if (
						!!queryFilters &&
						(queryFilters.selectedSipSegment.value === 185 ||
							queryFilters.selectedSipSegment.value === 0 ||
							queryFilters.selectedSipSegment.value === 358)
					) {
						obj['Individual OverAll Weighted % for YTD'] =
							(!!item.overall_ach_ytd &&
								item.overall_ach_ytd.toFixed(2) + ' %') ||
							'0.00 %'
					}
					obj['Individual Final Payout Amount'] =
						(!!item.final_payout_amount && item.final_payout_amount) || 0
					obj['Individual Final Payout %'] =
						(!!item.final_payout_percentage &&
							item.final_payout_percentage.toFixed(2) + ' %') ||
						'0.00 %'
				}
			}
			// if (isAccountManager) {
			//   ;[9, 10, 11, 12, 13, 14].forEach(x => {
			//     obj[x] = '-'
			//   })
			// }

			data.push(obj)
		})
		return [data, _arr]
	}

	const renderSelfData = () => {
		let [data, _arr] = makeSelfData()
		return (
			!!data &&
			data.map((item, i) => (
				<TR key={i}>
					{Object.values(item).map((val, j) =>
						j === 1 ? (
							<TD key={j} classes={`text-bold green-theme cursor-pointer`}>
								{val}
							</TD>
						) : (
							<TD key={j}>{val}</TD>
						)
					)}
				</TR>
			))
		)
	}

	const handleDownload = () => {
		setDownload(true)
	}

	const exportHeadings = self => {
		let heads = [
			self
				? 'Self'
				: isAccountManager
				? 'Account/Channel Name'
				: 'Employee Name',
			isAccountManager ? 'Account/Channel Code' : 'Employee Code',
			'Employee Email',
			'Role',
			'Segment',
			...(!!queryFilters &&
			(queryFilters.selectedSipSegment.value === 184 ||
				queryFilters.selectedSipSegment.value === 185 ||
				queryFilters.selectedSipSegment.value === 0)
				? ['Circle', 'Cluster']
				: []),
			...(!!queryFilters &&
			(queryFilters.selectedSipSegment.value === 358 ||
				queryFilters.selectedSipSegment.value === 0)
				? ['Vertical']
				: []),
			'Employees on SIP',
			'Qualified for SIP',
			'Summarized SIP Penetration %',
			'Summarized SIP Utilization %',
			`Weighted SIP Target vs Acheivement % (${
				!!queryFilters && queryFilters.selectedPeriodType.label
			})`,
		]

		let individualHeads = [
			'On Target Payout (Default)',
			'Individual OverAll Weighted % for MTD',
			'Individual OverAll Weighted % for QTD',
			'Individual OverAll Weighted % for YTD',
			'Individual Final Payout Amount',
			'Individual Final Payout %',
		]
		let KpiCodes
		if (self) {
			KpiCodes =
				!!sipWidgetManagerTargetList &&
				!!sipWidgetManagerTargetList[0] &&
				!!sipWidgetManagerTargetList[0].header
					? sipWidgetManagerTargetList[0].header
					: []
		} else {
			KpiCodes =
				!!sipWidgetList && !!sipWidgetList[0] && !!sipWidgetList[0].header
					? sipWidgetList[0].header
					: []
		}
		let subHeads = []

		let dynamicHeads = Object.entries(KpiCodes).map((header, i) => {
			// let unit,
			//   format = ''
			const [key, value] = header
			subHeads = Object.entries(value).filter(
				([key]) =>
					![
						'widget_type_code',
						'widget_type_measurement_name',
						'widget_type_measurement_unit',
						'asset_id',
						'employee_email_id',
						'is_parent_kpi',
						'widget_type_name',
						'widget_type_code_sequence_id',
					].includes(key)
			)
			return Object.values(subHeads).map(
				sub => `${value['widget_type_name']}_${sub[1]}`
			)
		})

		heads = heads.concat(_.flattenDeep(dynamicHeads))
		heads = heads.concat(individualHeads)
		return heads
	}

	const reloadWidgetDrilldown = (
		//	isExport = false,
		//page = 0,
		isReload = false
	) => {
		loadSIPWidgetsAccountDetails(getAccountAsset_id, isReload)
	}

	const handleScrollUpdate = values => {
		const { scrollTop, scrollHeight, clientHeight, scrollYPossible } = values
		if (
			scrollHeight - Math.round(scrollTop) <= clientHeight + 10 &&
			scrollYPossible
		) {
			setScrollAtBottom(true)
			reloadWidgetDrilldown(false)
		}
	}

	const onScrollView = async e => {
		let bottom = e.scrollHeight - e.scrollTop - e.clientHeight <= 1
		if (bottom) {
			setScrollAtBottom(true)
			reloadWidgetDrilldown(false)
		}
	}

	const generateExcelData = data => {
		let newData = []
		data.forEach(employee => {
			if (employee?.Months?.length) {
				employee.Months.forEach((month, index) => {
					const newEmployee = {}
					for (const key of Object.keys(employee || {})) {
						if (key === 'Months') {
							newEmployee.Months = month
						} else if (Array.isArray(employee[key])) {
							newEmployee[key] = employee[key][index]
						} else if (employee[key] !== null && employee[key] !== undefined) {
							newEmployee[key] = employee[key]
						}
					}
					newData.push(newEmployee)
				})
			} else {
				newData = data
			}
			//newData.push(data)
		})
		return newData
	}

	// const flattenArray = (obj) =>{
	//   const result = {};
	//   let months = sipWidgetList && !!sipWidgetList[0] && !!sipWidgetList[0].months ? sipWidgetList[0].months : []
	//   for (const key of Object.keys(obj || {})) {
	//     if (typeof obj[key] === 'object' && key !== 'Months') {
	//       const nested = flattenArray(obj[key]);
	//       for (const nestedKey of Object.keys(nested)) {
	//         let value = months[nestedKey]
	//         result[`${key}.${value}`] = nested[nestedKey];
	//       }
	//     }
	//     // if (Array.isArray(obj[key]) && key !== 'Months') {
	//     //   obj[key] =  !!obj[key] && obj[key].map((e,index)=> {
	//     //          //console.log(e, months[index])
	//     //          return months[index] + '-' + e})

	//     //    result[key] = obj[key].join('/')
	//     //  }
	//     else if (key === 'Months'){
	//        // result[key] = obj[key].join('/')
	//     }
	//     else {
	//       result[key] = obj[key];
	//     }
	//   }
	//   return result;
	// }

	const getReporteeExcel = () => {
		let [data, arr] = makeTableData()
		let flattenResult = []
		// for(let item in data){
		//   let excelObj = flattenArray(data[item])
		//   flattenResult.push(excelObj)
		// }
		flattenResult = generateExcelData(data)
		return flattenResult
	}

	const getAccountExcel = () => {
		let accountData = accountMonthwiseData
		let flattenResult = []
		// for(let item in accountData){
		//   let excelObj = flattenArray(accountData[item])
		//   flattenResult.push(excelObj)
		// }
		flattenResult = generateExcelData(accountData)
		return flattenResult
	}

	const renderExportData = () => {
		let accountData = getAccountExcel()
		let dataSet = getReporteeExcel()
		let [selfData, arr] = makeSelfData()
		const fileName = 'SIP_Download'
		return (
			<ExcelFile filename={fileName} hideElement={true}>
				{[
					{
						sheet: 'Self Data',
						data: isRoleAccess ? selfData : [],
						heads:
							selfData?.length && isRoleAccess ? Object.keys(selfData[0]) : [],
					},
					{
						sheet: 'SIP Data',
						data: dataSet || [],
						heads: dataSet?.length ? Object.keys(dataSet[0]) : [],
					},
					{
						sheet: 'Account Data',
						data: accountData || [],
						heads: accountData?.length ? Object.keys(accountData[0]) : [],
					},
				].map(item => (
					<ExcelSheet data={item.data} name={item.sheet}>
						{item.heads?.map(headerItem => (
							<ExcelColumn label={headerItem} value={headerItem} />
						))}
					</ExcelSheet>
				))}
			</ExcelFile>
		)
	}

	const widgetMainView =
		isReporteesLoading ||
		isChannelLoading ||
		isManagerLoading ||
		isReporteesSipKpiLoading ||
		isManagerSipKpiLoading ? (
			<Stack>
				<Skeleton height='20px' />
				<Skeleton height='20px' />
				<Skeleton height='20px' />
			</Stack>
		) : (
			<Box>
				<Flex mb={3} p={3} alignItems='center'>
					<IconButton
						as={BiDownload}
						w={6}
						h={6}
						onClick={() => {
							sectionDetailedTrack({
								category: GA_CATEGORY_DASHBOARD,
								action: 'Widget SIP View',
								label: 'Download',
							})
							handleDownload()
						}}
						isDisabled={
							showAccountTable &&
							sipWidgetAccountList?.length !== accountMonthwiseData?.length
								? true
								: false
						}
						className={`cursor-pointer`}
					/>
					&nbsp;&nbsp;
					{download && renderExportData()}
					{!isAccountManager &&
						!!sipReporteeList &&
						sipReporteeList.map((item, i) => (
							<>
								{i !== 0 ? '/' : null}
								<Text
									fontWeight='bold'
									onClick={() => {
										sectionDetailedTrack({
											category: GA_CATEGORY_DASHBOARD,
											action: 'Widget SIP View',
											label: 'Load Resource For Asset',
										})
										dashboardDispatch({
											type: dashboardConst.ADD_ACTIVE_FILTER_OPTION,
											activeFilterOption: item,
										})
										loadResourceForAsset(item)
									}}
									color={localStorage.getItem('color')}
									cursor='pointer'
									textDecoration='underline'
								>
									{' '}
									{item.operating_asset_first_name}{' '}
								</Text>
							</>
						))}
				</Flex>
				{(isManagerSuccess && isManagerSipKpiSuccess) ||
				(isReporteesSuccess && isReporteesSipKpiSuccess) ? (
					<VStack spacing={8} alignItems={'flex-start'} marginLeft={`50px`}>
						{isRoleAccess &&
						managerDetails?.length &&
						!!managerDetails[0]?.operating_asset_first_name ? (
							<>
								<DrillDownTable
									theadings={renderSelfHeadings(true)}
									tdata={renderSelfData()}
								/>
								<Divider />
							</>
						) : null}

						{reporteeList?.length > 0 &&
						!!reporteeList[0].operating_asset_first_name ? (
							<DrillDownTable
								theadings={renderHeadings()}
								tdata={renderData()}
							/>
						) : null}
						{/* <Box
                w='100%'
                h={isMobileView ? 'calc(100vh - 100px)' : 'calc(100vh - 160px)'}
                mt='16px'
                overflow='auto'
                templateColumns='repeat(12, 1fr)'
                gap={5}
              > */}
						{showAccountTable ? (
							// <Scrollbar onUpdate={widgetAccounts.length > 8 ? handleScrollUpdate : null}>
							<DrillDownTable
								theadings={renderAccountHeadings()}
								tdata={renderAccountData()}
							/>
						) : // </Scrollbar>
						null}
						{showAccountTable &&
						sipWidgetAccountList?.length !== accountMonthwiseData?.length
							? [1, 2, 3].map(item => {
									return (
										<Skeleton
											key={Math.random() * 3.3}
											minH='20px'
											w='full'
											my={3}
										/>
									)
							  })
							: null}

						{/* </Box> */}
					</VStack>
				) : null}
				<Divider />
				<br />
			</Box>
		)

	return (
		<Box bg='white' rounded='md' p='8px'>
			{widgetMainView}
		</Box>
	)
}

export default WidgetSIPDynamicViewSA
